export const ABBREVIATION_COLUMN_INDEX = 0;
export const DATE_COLUMN_INDEX = 1;
export const DESCRIPTION_COLUMN_INDEX = 2;
export const RESPONSIBLE_COLUMN_INDEX = 3;
export const DEADLINE_COLUMN_INDEX = 4;
export const TYPE_COLUMN_INDEX = 5;
export const STATUS_COLUMN_INDEX = 6;
export const IS_CLOSED_COLUMN_INDEX = 7;
export const IS_ELOG_COLUMN_INDEX = 8;
export const ATTACHMENT_COLUMN_INDEX = 9;
export const WAS_MODIFIED_COLUMN_INDEX = 10;
export const WAS_MODIFIED_COLUMN_LETTER = "K";
export const TYPE_STR_COLUMN_INDEX = 11;
export const ID_COLUMN_INDEX = 12;
export const ID_COLUMN_LETTER = "M";
export const VERSION_NUMBER_NO_COLUMN_INDEX = 13;
export const PARENT_ID_COLUMN_INDEX = 14;
export const PARENT_ROW_NO_COLUMN_LETTER = "P";
export const PARENT_ROW_NO_COLUMN_INDEX = 15;
export const RELATION_STRING_COLUMN_INDEX = 16;
export const ROW_NO_COLUMN_INDEX = 17;
export const ERROR_COLUMN_LETTER = "T";
export const ERROR_COLUMN_INDEX = 19;

export const HEADER_COOPERATION_DATE_CELL = "E4"
export const HEADER_COOPERATION_ID_CELL = "J1"
export const HEADER_CELLS_HEIGHT = 6;
export const FOOTER_CELLS_HEIGHT = 6;
export const HAS_NO_PARENT = "-"
export const ID_COLUMN = 13;
export const PARENT_COLUMN_INDEX = 16;
export const PARENT_COLUMN_LETTER = "P";
export const PARENT_RELATIONSHIP_COLUMN = 17;
export const PARENTS_ABBREVIATION_COLUMN_INDEX = 19; 
export const GET_CURRENT_ROW_NO_FX = "=ROW()";
export const GET_PARENT_ROW_NUMBER_FX = `=IFERROR(MATCH(INDIRECT(ADDRESS(ROW(),${PARENTS_ABBREVIATION_COLUMN_INDEX})),A:A,FALSE), "-")`
export const RELATION_EXCEL_FX = `=(CONCATENATE(IF(EXACT(INDIRECT(ADDRESS(ROW(),${PARENT_COLUMN_INDEX})),"${HAS_NO_PARENT}"),ROW(), CONCATENATE(INDIRECT(ADDRESS(INDIRECT(ADDRESS(ROW(),${PARENT_COLUMN_INDEX})),${PARENT_RELATIONSHIP_COLUMN})),".",ROW()))))`
export const GET_PARENTS_ID_FX = `=IF(INDIRECT(ADDRESS(ROW(),${PARENT_COLUMN_INDEX})) = "${HAS_NO_PARENT}","${HAS_NO_PARENT}",INDIRECT(ADDRESS(INDIRECT(ADDRESS(ROW(),${PARENT_COLUMN_INDEX})),${ID_COLUMN})))`
export const EMPTY = "";
export const OPEN = "o";
export const CLOSED = "c";
export const HAS_ELOG = "Igen";
export const HAS_NO_ELOG = "Nem";
export const URL = "https://reminder.ceh.hu";
export const PROJECT_STAGE = "prod";
export const WAS_MODIFIED = "*";
export const DONT_ALLOW_EDIT_FROM_COLUMN = WAS_MODIFIED_COLUMN_LETTER
export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const NEW_SHEET_ROWCOUNT = 12;
export const FILTER_ROW = 6;

export const ENTRY_TYPES = {
    0: "F",
    1: "I",
    2: "D"
}
